@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?b05ig3');
  src:  url('icomoon.eot?b05ig3#iefix') format('embedded-opentype'),
    url('icomoon.ttf?b05ig3') format('truetype'),
    url('icomoon.woff?b05ig3') format('woff'),
    url('icomoon.svg?b05ig3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wild:before {
  content: "\e921";
}
.icon-farmers:before {
  content: "\e900";
}
.icon-gift:before {
  content: "\e902";
}
.icon-meat:before {
  content: "\e91c";
}
.icon-text:before {
  content: "\e946";
}
.icon-eartag-o:before {
  content: "\e947";
}
.icon-breed:before {
  content: "\e948";
}
.icon-butcher:before {
  content: "\e949";
}
.icon-earth:before {
  content: "\e94a";
}
.icon-knife:before {
  content: "\e94b";
}
.icon-invest:before {
  content: "\e944";
}
.icon-pinterest:before {
  content: "\e943";
}
.icon-dashboard:before {
  content: "\e941";
}
.icon-logout:before {
  content: "\e942";
}
.icon-trash:before {
  content: "\e93f";
}
.icon-frozen:before {
  content: "\e940";
}
.icon-whatsapp:before {
  content: "\e93c";
}
.icon-credit-card:before {
  content: "\e93b";
}
.icon-package:before {
  content: "\e93a";
}
.icon-easter-day:before {
  content: "\e939";
}
.icon-christmas-tree:before {
  content: "\e938";
}
.icon-bio:before {
  content: "\e931";
}
.icon-grill:before {
  content: "\e932";
}
.icon-weight:before {
  content: "\e933";
}
.icon-check-circle:before {
  content: "\e930";
}
.icon-warning:before {
  content: "\e92f";
}
.icon-phone:before {
  content: "\e92b";
}
.icon-instagram:before {
  content: "\f16d";
}
.icon-twitter:before {
  content: "\e92c";
}
.icon-email:before {
  content: "\e92d";
}
.icon-facebook:before {
  content: "\e92e";
}
.icon-return:before {
  content: "\e924";
}
.icon-circle-fill:before {
  content: "\e925";
}
.icon-lock-fill:before {
  content: "\e926";
}
.icon-piggy:before {
  content: "\e927";
}
.icon-receipt:before {
  content: "\e928";
}
.icon-Star-fill:before {
  content: "\e929";
}
.icon-user-add:before {
  content: "\e92a";
}
.icon-eartag:before {
  content: "\e922";
}
.icon-angle-down:before {
  content: "\e923";
}
.icon-arrow-up:before {
  content: "\e904";
}
.icon-angle-top:before {
  content: "\e904";
}
.icon-mission:before {
  content: "\e901";
}
.icon-plus:before {
  content: "\e91d";
}
.icon-minus:before {
  content: "\e91e";
}
.icon-calendar:before {
  content: "\e917";
}
.icon-close:before {
  content: "\e918";
}
.icon-cow:before {
  content: "\e919";
}
.icon-truck:before {
  content: "\e91a";
}
.icon-users:before {
  content: "\e91b";
}
.icon-arrow-down:before {
  content: "\e912";
}
.icon-arrow-left:before {
  content: "\e913";
}
.icon-arrow-right:before {
  content: "\e914";
}
.icon-arrow-top:before {
  content: "\e915";
}
.icon-grutto:before {
  content: "\e916";
}
.icon-calories:before {
  content: "\e90d";
}
.icon-heart:before {
  content: "\e90e";
}
.icon-share:before {
  content: "\e90f";
}
.icon-star:before {
  content: "\e910";
}
.icon-time:before {
  content: "\e911";
}
.icon-back:before {
  content: "\e908";
}
.icon-checkmark:before {
  content: "\e909";
}
.icon-info:before {
  content: "\e90a";
}
.icon-location:before {
  content: "\e90b";
}
.icon-magnify:before {
  content: "\e90c";
}
.icon-recipes:before {
  content: "\e907";
}
.icon-faq:before {
  content: "\e905";
}
.icon-basket:before {
  content: "\e903";
}
.icon-user:before {
  content: "\e906";
}
.icon-dish:before {
  content: "\e937";
}
.icon-printer:before {
  content: "\e935";
}
.icon-kadeos:before {
  content: "\e945";
}
.icon-sofort:before {
  content: "\e93d";
}
.icon-giropay:before {
  content: "\e93e";
}
.icon-klarna:before {
  content: "\e934";
}
.icon-ideal:before {
  content: "\e936";
}
.icon-visa:before {
  content: "\f1f0";
}
.icon-mastercard:before {
  content: "\f1f1";
}
.icon-paypal:before {
  content: "\f1f4";
}
.icon-amex:before {
  content: "\f1f3";
}
.icon-google-pay:before {
  content: "\e91f";
}
.icon-apple-pay:before {
  content: "\e920";
}