.farmshop__item, .farmshop__feedback{
    display: flex;
    flex-direction: column;
    margin: 5px !important;
    background-color: hsl(0, 0%, 100%);
    position: relative;

    .farmshop__title{
        display: flex;
        align-items: center;
        padding: 0.5rem;
        margin: 0;
        color: $darkest-grey !important;
        font-size: 0.8rem;
        font-family: $title_font;
        @include media-breakpoint-up(md) {
            font-size: 1.05rem;
        }
        &:hover{
            color: $artichoke-dark !important;
        }
        line-height: 1.2;
        min-height: 46px;
        @include media-breakpoint-up(md) {
            min-height: 56px;
        }
        @media (max-width: 400px) {
            min-height: 62px;
        }
        flex-direction: column;
        align-items : flex-start !important;
        justify-content: center;
        line-height: 1;
        .bio-text{
            font-size: 12px;
            font-family: $body_font;
        }
    }
    .body{
        height: 100%;
        display: flex;
        flex-direction: column;

        .farmshop__item__view{
            margin-top: 0.5rem;
            height: 28px;
            width: 100%;
            background-color: $artichoke_dark;
            color: #fff!important;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $artichoke_dark;
            font-size: 0.8rem;
            text-transform: uppercase;
            @include media-breakpoint-up(lg) {
                font-size: 0.9rem;
            }
            &:hover{
                background-color: $darkest_grey;
                border-color: $darkest_grey;
            }
        }
    }
    // Image
    figure.img{
        position: relative;
    }
    .img{
        img{
            height: 80px;
            width: 100%;
            object-fit: cover;
            @include media-breakpoint-up(md){
                height: 110px;
            }
        }
    }

    //Farmer
    &__farmer{
        display: flex;
        align-items: baseline;
        margin-top: -20px;
        padding:0 0.5rem;
        position: relative;
        z-index: 5;
    }

    // Out Of Stock
    &.out-of-stock{
        position: relative;
        .farmshop__item__title{
            a{
                color: #666666 !important;
            }
        }
        .farmshop_stock{
            position: absolute;
            width: 100%;
            height: 80px;
            z-index: 7;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 0 0.6rem;
            text-align: center;
            @include transition(all, 0.4s, ease);
            @include media-breakpoint-up(md){
                height: 110px;
                padding: 0 1rem;
            }
            span.txt, span.link{
                color:$darkest-grey;
                font-size: 0.7rem;
                @include media-breakpoint-up(md){
                    font-size: 0.9rem;
                }
            }
            span.txt{
                font-weight: bold;
                cursor: pointer;
                line-height: 1.1;
            }
            span.link{
                text-decoration: underline !important;
                cursor: pointer;
            }
        }
        .out-of-stock__btn{
            width: 100%;
            background-color: #ECEAE7;
            color: #666666;
            margin: 0.5rem 0 0 0;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.65rem;
            text-align: center;
            line-height: 1;
            @include media-breakpoint-up(sm){
                font-size: 0.7rem;
            }
            @include media-breakpoint-up(md){
                font-size: 0.8rem;
            }
        }
    }
    // organic logo
    &.organic{
        figure{
            &::before{
                content: " ";
                width: 36px;
                height: 24px;
                background-image: url(../../../../../img/bio/organic.svg);
                background-size: 28px;
                background-position: center;
                background-repeat: no-repeat;
                background-color: #a2bd59;
                overflow: hidden;
                position: absolute;
                top: -12px;
                right: 0.5rem;
                @include border-radius(1px);
            }
        }
    }
    &.type--salmon{
        figure{
            &::before{
                content: " ";
                width: 40px;
                height: 30px;
                background-image: url(../../../../../img/bio/msc.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-color: transparent;
                position: absolute;
                top: -12px;
                right: 0.5rem;
            }
        }
    }

    &.new{
        .farmshop__title{
            padding-right: 1.5rem;
        }
        > a{
            &::before{
                display: flex;
                position: absolute;
                background-size: contain;
                width: 50px;
                height: 50px;
                right: -4px;
                top: -4px;

                .nl &{
                    content: " ";
                    background-image: url(../../../../../img/farmshop/new-nl.svg);
                }
                .de &{
                    content: " ";
                    background-image: url(../../../../../img/farmshop/new-de.svg);
                }
            }
        }
    }

    &.highlight-bbq{
        .farmshop__title{
            padding-right: 2.5rem;
        }
        > a{
            &::before{
                display: flex;
                position: absolute;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 40px;
                height: 42px;
                right: 4px;
                top: -5px;

                .nl &{
                    content: " ";
                    background-image: url(../../../../../img/package/highlight_bbq_nl.svg);
                }
                .de &{
                    content: " ";
                    background-image: url(../../../../../img/package/highlight_bbq_de.svg);
                }
            }
        }
    }
    &.highlight-christmas{
        .farmshop__title{
            padding-right: 2.5rem;
        }
        > a{
            &::before{
                display: flex;
                position: absolute;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 40px;
                height: 42px;
                right: 4px;
                top: -5px;

                .nl &{
                    content: " ";
                    background-image: url(../../../../../img/package/highlight_xmas2_nl.svg);
                }
                .de &{
                    content: " ";
                    background-image: url(../../../../../img/package/highlight_xmas2_de.svg);
                }
            }
        }
    }
}
.farmshop__feedback{
    .img{
        background-color: $vanilla-lightest;
        img{
            object-fit: contain;
        }
    }
    .body{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .cta{
        margin-top: 0.5rem;

        .btn{
            height: 28px;
            border-color: $artichoke_dark !important;
            color: $artichoke_dark !important;
            font-size: 0.8rem;
            @include media-breakpoint-up(sm){
                font-size: 0.9rem;
            }

            &:hover{
                background-color: $artichoke_dark !important;
                color: #fff !important;
            }
        }
    }
}
.bundle__items{
    .farmshop__item, .farmshop__feedback{
        width: calc(50% - 10px);
        @media (min-width: 576px) {
            width: calc(50% - 20px);
            margin: 10px !important;
        }
        @media (min-width: 992px) {
            width: calc(25% - 20px);
        }
        @media (min-width: 1200px) {
            width: calc(25% - 20px);
        }
        @media (min-width: 1400px) {
            width: calc(25% - 20px);
        }
    }
}


// call to action -------------
.quantity{
    display: flex;
    flex-direction: row-reverse;

    .number{
        font-weight: 500;
        text-align: center;
        width: 0px;
        opacity: 0;
        @include transition(all, 0.3s, ease);
        input{
            width: 100%;
        }
    }
    .plus-btn, .minus-btn{
        display: flex;
        @include transition(all, 0.3s, ease);
        cursor: pointer !important;
    }
    .plus-btn{
        width: 100%;
        background-color: $artichoke_dark;
        color: #fff !important;
        justify-content: center;
        align-items: center;
        border: 1px solid $artichoke_dark;
        span{
            margin-right: 0.5rem;
            text-transform: uppercase;
        }
        &:hover{
            background-color: $darkest_grey !important;
            border-color: $darkest_grey !important;
        }
    }
    .minus-btn{
        align-items: center;
        justify-content: center;
        width: 0;
        overflow: hidden;
        opacity: 0;
    }

    &.enabled{
        .number{
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 2;
            opacity: 1;
            input{
                width: 90%;
                height: 100%;
                padding: 0;
                margin: 0;
                border: 0;
                text-align: center;
                font-size: 1.1rem;
                background-color: #fff;
                pointer-events: none;
            }
        }
        .plus-btn{
            span{
                display: none;
            }
        }
        .minus-btn{
            border: 1px solid $artichoke_dark !important;
            display: flex;
            opacity: 1;
            &:hover{
                background-color: $artichoke_lightest;
            }
        }
    }
}
.farmshop__item, .farmshop__full{
    .cta{
        margin-top: 0.5rem;
        .btn{
            height: 30px;
            font-weight: bold;
            font-size: .9rem;
            padding: 7px;
        }
        i{
            font-size: 0.9rem;
        }
    }
}
.farmshop{
    .quantity{
        .plus-btn, .minus-btn{
            height: 28px;
        }
        .number{
            height: 28px;
        }
        &.enabled{
            .plus-btn{
                width: 28px;
            }
            .minus-btn{
                width: 28px;
            }
        }
        .disabled{
            background-color: #bababa;
            border-color: #bababa;
            cursor: not-allowed !important;

            &:hover{
                background-color: #bababa !important;
                border-color: #bababa !important;
            }
        }
    }
}
.farmshop__item{
    .quantity{
        margin-top: 0.5rem;
    }
}
.farmshop__full{
    .quantity{
        .plus-btn, .minus-btn{
            height: 36px;
        }
        .number{
            height: 36px;
        }
        &.enabled{
            .plus-btn{
                width: 36px;
            }
            .minus-btn{
                width: 36px;
            }
        }
    }
}


.farmshop__item__info{
    color: #666666;
    display: flex;
    align-items: baseline;
    line-height: 1;
    font-family: $body_font;
    padding-left: 2px;

    .price, .p-prev{
        font-size: 1.1rem;
        @include media-breakpoint-up(md) {
            font-size: 1.3rem;
        }
        .amount{
            margin: 0;
        }
    }
    .price{
        color: #666666;
        .amount{
            font-family: $body_font;
        }
    }
    sup {
        top: -0.3em;
        font-size: 0.9rem;
    }
    .sep{
        margin: 0 3px;
        @include media-breakpoint-up(sm){
            margin: 0 5px;
        }
    }

    .sale_price{
        &.p-prev{
            color: $red;
            margin-right: 5px;
            .amount{
                font-family: $body_font;
            }
        }
        &.price{
            font-weight: normal !important;
            margin-left: 5px;
            position: relative;
            color: #999999;
            .amount{
                font-weight: normal !important;
                font-size: 0.9rem !important;
            }

            &::after{
                content: "";
                height: 1px;
                width: 100%;
                position: absolute;
                background: #666666;
                left: 0;
                transform: rotate(-8deg);
                top: 6px;
            }
            sup{
                top: -2px !important;
                font-size: 0.75rem !important;
            }
        }
    }
    .weight{
        white-space: nowrap;
        font-size: 0.8rem;
        @include media-breakpoint-up(md){
            font-size: 1rem;
        }
    }
}


// Brand Banner --------
.banner-holder{
    width : calc(100% - 10px);
    margin : 0 auto;
    position : relative;
    overflow-x : hidden;
    overflow-y : hidden;
    display : flex;
    align-items : center;
    justify-content : center;
    flex-direction: column;
    @media (min-width: 576px) {
        width : calc(100% - 20px);
    }
    img{
        width : 100%;
        height : 100%;
        display : block;
        object-fit : cover;
    }
    .banner-link, .banner-no-link{
        margin : 5px auto;
    }
    .banner-link{
        width: 100%;
        position: relative;
    }
    .banner-no-link{
        width: 100%;
        position: relative;
    }
}

// old --------------------
.farmshop__item__farmer img{
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #fff;
    margin-bottom: -4px;
    margin-right: 6px;
}
.farmshop__item__farmer .farmshop_farmer_name{
    font-size: 13px;
    color: #666666;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 2;
}

// Notification ------------------
.response{
    position: fixed;
    top: 135px;
    width: calc(100% - 30px);
    background-color: #fff;
    @include border-radius(5px);
    border: 1px solid;
    display: flex;
    align-items: center;
    line-height: 1.2;
    padding: 10px;
    z-index: 1051;

    @include media-breakpoint-up(sm) {
        margin: 0 15px;
        width: 500px;
        left: 50%;
        margin-left: -250px;
    }
    @include media-breakpoint-up(md) {
        top: 160px;
        width: 600px;
        margin-left: -300px;
    }
    @include media-breakpoint-up(lg) {
        top: 105px;
        width: 700px;
        margin-left: -350px;
    }

    &:before{
        font-family: 'icomoon';
        font-size:1.5rem;
        margin-right: 10px;
    }

    &.success, &#mce-success-response{
        border-color:$artichoke-dark;
        color:$artichoke-dark;
        &:before{
          content: "\e930";
        }
    }
    &.error{
        border-color:$red_color;
        color:$red_color;
        &:before{
          content: "\e92f";
        }
    }
    &.warning{
        border-color:$caramel_color;
        color:#666666;
        &:before{
          content: "\e90a";
          color:$caramel_color;
        }
    }
}