.icon-cat {
    @each $val in $cat-values {
        &-#{$val} {
            background-image: url(../../../../img/animals/#{$val}/#{$val}_grayscale.svg);
        }
    }
}
.icon-cat-center {
    @each $val in $cat-values {
        &-#{$val} {
            background-image: url(../../../../img/animals-icon-center/#{$val}/#{$val}_grayscale.svg);
        }
    }
}

.icon-cat-express{
    background-image: url(../../../../img/animals/express_grayscale.svg);
}
.icon-cat-christmas{
    background-image: url(../../../../img/animals/christmas_grayscale.svg);
}
.icon-cat-easter_pack{   
    background-image: url(../../../../img/animals/easter_pack_grayscale.svg);
    background-size: contain;   
}
.icon-cat-cow_chicken_pig{     
    background-image: url(../../../../img/animals/cow_chicken_pig/cow_chicken_pig_grayscale.svg);
    background-size: contain;
}
.icon-cat-heart_filled{     
    background-image: url(../../../../img/animals/icon-cat-heart_filled.svg);
    background-size: contain !important;    
}


.icon-cat-codfish{
    @extend .icon-cat-fish
}

.category{
    &__holder{
        padding:2rem 0;
        background-color:$artichoke-light;
        @include media-breakpoint-up(md) {
            padding:4rem 0;
        }
        .container{
            @include media-breakpoint-down(sm) {
                max-width: 100%; 
            }
        }
        .row{
            justify-content: center;
        }
    }
    &__item{
        @include transition(all, 0.2s, linear);
        color:#333333 !important;
        
        &:first-of-type{ margin-left: 8px;}
        &:last-of-type{ margin-right: 8px;}
    
        .body{
            min-width: 68px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 1.1rem;
            font-family: $body_font;
            @include border-radius(4px);
            text-align: center;
            cursor: pointer;
            @include transition(all, 0.2s, linear);
            @include media-breakpoint-up(md) {
                min-width: 100px;
            }
            @include media-breakpoint-up(lg) {
                min-width: 130px;
                margin: 8px 6px;
                padding:#{$grid-gutter-width * 2};
            }
            
        }
        &:hover, &.active{
            .body{
                background-color: $gray_light;
            }
        }
        .icon-cat {
            @each $val in $cat-values {
                &-#{$val} {
                    background-image: url(../../../../img/animals-icon/#{$val}/#{$val}_grayscale.svg);
                }
            }
        }
        figure{
            margin: 0;
            height: 36px;
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            background-size: contain;
            position: relative;
            @include media-breakpoint-up(lg) {
                height: 70px;
            }
            img{
                display: block;
                max-width: 100%;
            }
            &.icon-cat-heart_filled{
                background-size: 45% !important;
            }
        }
        .title{
            line-height: 1;
            white-space: nowrap;
            margin: 8px 0 0 0;
            font-size: 0.9rem;
            font-family: $title_font;
            @include media-breakpoint-up(lg) {
                margin: 18px 0 0 0;
                font-size: 1.1rem;
            }         
        }
    }
    &__number{
        background-color: $caramel_color;
        width: 40px;
        height: 32px;
        @include border-radius(4px);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    
    figure{
        &.organic{
            &:after{
                content: "";
                background: url(../../../../img/bio/organic-certification.svg);
                width: 24px;
                height: 18px;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                background-color: #95c11f;
                @include border-radius(2px);
                position: absolute;
                left: 2px;
                top: 2px;
                @include media-breakpoint-up(lg) {
                    width: 42px;
                    height: 28px;
                    left: 5px;
                    top: 5px;
                }
            }
        }
        &.organic__ab{ //Agriculture Biologique for the FR
            &:after{
                content: "";
                background: url(../../../../img/bio/agriculture-biologique.svg);
                width: 26px;
                height: 30px;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                top: 0;
                @include media-breakpoint-up(lg) {
                    width: 34px;
                    height: 40px;
                    left: 5px;
                    top: 5px;
                }
            }
        }
        &.organic__label-rouge{
            &:after{
                content: "";
                background: url(../../../../img/bio/label-rouge.svg);
                width: 25px;
                height: 20px;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                top: 0;
                @include media-breakpoint-up(lg) {
                    width: 40px;
                    height: 36px;
                    left: 2px;
                    top: 2px;
                }
            }
        }
        &.organic__sa{ //soil association for the UK
            &:after{
                content: "";
                background: url(../../../../img/bio/sa_organic_black.svg);
                width: 30px;
                height: 30px;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                @include border-radius(2px);
                position: absolute;
                left: 0;
                top: 0;
                @include box-shadow(1px, 1px, 10px, rgba(0,0,0,0.2));
                @include media-breakpoint-up(lg) {
                    width: 40px;
                    height: 40px;
                    left: 5px;
                    top: 5px;
                }
            }
        }
        &.badge__new{
            &:after{
                content: "";
                background: url(../../../../img/badge__new.svg);
                width: 22px;
                height: 22px;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                position: absolute;
                right:0;
                top: 0;
                @include media-breakpoint-up(lg) {
                    width: 40px;
                    height: 40px;
                    right: -2px;
                    top: -2px;
                }
            }
        }

    }
}

[class^="icon-cat"] {
    background-position: bottom center;
    background-repeat: no-repeat;
}


.icon-cat-wild, .icon-cat-game{
    @extend .icon-cat-deer;
}
.icon-cat-salmon{
    @extend .icon-cat-fish;
}


.x-scroll{
    @include media-breakpoint-down(md) {
        overflow: initial;
    }
    .container{
        @include media-breakpoint-down(md) {
            padding: 0 10px;
        }
        > .row{
            @include media-breakpoint-down(md) {
                flex-wrap: nowrap;
                overflow-x: auto;
                padding-top: 20px;
                padding-left: 2px;
                padding-right: 2px;
                justify-content: flex-start !important;
            }

            > div {
                @include media-breakpoint-down(md) {
                    flex: 0 0 auto;
                }

                //item margin
                margin-left: 4px;
                margin-right: 4px;
                @include media-breakpoint-up(md) {
                    margin-left: 0px;
                    margin-right: 0px;
                }
    
                // item width
                @include media-breakpoint-down(md) {
                    width: 42%;
                }
                @include media-breakpoint-down(sm) {
                    width: 340px;
                }
                @include media-breakpoint-down(xs) {
                    width: 290px;
                }
                @media (max-width: 360px) {
                    width: 270px;
                }
                .package__item .product-info .item{
                    @include media-breakpoint-down(md) {
                        width: auto;
                    }
                }
            }
            .package__item{
                @include media-breakpoint-down(md) {
                    margin-bottom: 20px !important;
                }
            }
        }
    }
    .package__info{
        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
    }
    .percentage__box{
        @include media-breakpoint-down(md) {
            transform: scale(0.7);
            transform-origin: center;
            margin-top: -10px;
            margin-bottom: -25px;
        }
    }
    .package__title a{
        @include media-breakpoint-down(md) {
            min-height: 40px;
            align-items: center;
        }
    }
    .package__slider{
        .carousel__bottom{
            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }
        .carousel{
            @include media-breakpoint-down(md) {
                height: 160px;
            }
        }
    }
    .tooltip__content{
        .body{
            display: none;
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }
    .highlight--express{
        .package__title{
            a{
              &:before{
                @include media-breakpoint-down(md) {
                    top: -20px; 
                }
              }
            }
        }
    }
}