/* brown-regular */
@font-face {
  font-family: 'Brown Regular';
  font-style: normal;
  font-weight: 300;
  font-display: $font-display;
  src: url('#{$brown-regular-path}/brown-regular.eot'); /* IE9 Compat Modes */
  src: local('Brown Regular'), local('Brown Regular'),
       url('#{$brown-regular-path}/brown-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$brown-regular-path}/brown-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$brown-regular-path}/brown-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$brown-regular-path}/brown-regular.ttf') format('truetype'), /* Safari, Android, iOS */
}

/* brown-bold */
@font-face {
  font-family: 'Brown Bold';
  font-style: normal;
  font-weight: 700;
  font-display: $font-display;
  src: url('#{$brown-bold-path}/brown-bold.eot'); /* IE9 Compat Modes */
  src: local('Brown Bold'), local('Brown Bold'),
       url('#{$brown-bold-path}/brown-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$brown-bold-path}/brown-bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$brown-bold-path}/brown-bold.woff') format('woff'), /* Modern Browsers */
       url('#{$brown-bold-path}/brown-bold.ttf') format('truetype'), /* Safari, Android, iOS */
}

// ----------------------------------------------

$Brown-Regular:       'Brown Regular', "Helvetica", "Arial", "Lucida Grande", "sans-serif"!default;
$Brown-Bold:          'Brown Bold', "Helvetica", "Arial", "Lucida Grande", "sans-serif"!default;
$body_font:           'Brown Regular', "Helvetica", "Arial", "Lucida Grande", "sans-serif"!default;
$title_font:          'Brown Bold', "Helvetica", "Arial", "Lucida Grande", "sans-serif"!default;


$font-family-base: $body_font;

body{
    font-size: 15px;
    font-family: $body_font;
}
.bold{ font-weight: bold;}
h1,h2,h3,h4,h5,h6{
  font-family: $title_font;
}
b, strong{
  font-family: $title_font;
}
.heading{
  &--h1{
    font-family: $title_font;
    font-size: 1.75rem; //28px
    line-height: 1.1;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      font-size: 2.25rem; //36px
    }
    @include media-breakpoint-up(lg) {
      font-size: 2.625rem; //42px
    }
  }
  &--h2{
    font-family: $title_font;
    font-size: 1.375rem; //22px
    line-height: 1.1;
    @include media-breakpoint-up(md) {
      font-size: 1.75rem; //28px
    }
    @include media-breakpoint-up(lg) {
      font-size: 2.125rem; //34px
    }
  }
  &--h3{
    font-family: $title_font;
    font-size: 1.25rem; //20px
    line-height: 1.1;
    @include media-breakpoint-up(md) {
      font-size: 1.625rem; //26px
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.875rem; //30px
    }
  }
  &--h4{
    font-family: $title_font;
    font-size: 1.125rem; //18px
    line-height: 1.1;
    @include media-breakpoint-up(md) {
      font-size: 1.375rem; //22px
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.625rem; //26px
    }
  }
  &--h5{
    font-family: $title_font;
    font-size: 1rem; //16px
    line-height: 1.1;
    @include media-breakpoint-up(md) {
      font-size: 1.25rem; //20px
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.5rem; //24px
    }
  }
  &--h6{
    font-family: $title_font;
    font-size: 0.9rem;
    line-height: 1.1;
    @include media-breakpoint-up(md) {
      font-size: 1.1rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.2rem;
    }
  }
}

.semibold--title{
  font-family:$title_font !important;
}
.medium--title{
  font-family:$body_font !important;
  font-weight: 300;
}
.light--title{
  font-family:$body_font !important;
  font-weight: 300;
}

.display-1{ // 100px / 16 = 6.25rem
  font-size: 3.25rem !important;
  @include media-breakpoint-up(md) {
    font-size: 6.25rem !important;
  }
}
.display-2{ // 70px / 16 = 4.375rem
  font-size: 2.375rem !important;
  @include media-breakpoint-up(md) {
    font-size: 4.375rem !important;
  }
}
.display-3{ // 48px / 16 = 3.2rem
  font-size: 2.1rem !important;
  @include media-breakpoint-up(md) {
    font-size: 3.2rem !important;
  }
}

.module {
  &__text-box {
    h1, h2, h3 {
      display: block;
    }
    p {
      display: block;
      width: 100%;
    }
  }
}